import { styled } from '@smooth-ui/core-sc';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 190px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 180px;
`;

export default IconWrapper;
