import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
//import BgGold from '../../components/CustomComponents/Backgrounds/BgGold';
//import BgBlack from '../../components/CustomComponents/Backgrounds/BgBlack';
import IconWrapper from '../../components/CustomComponents/SvgIcon/IconWrapper';
import SvgIcon from '../../components/CustomComponents/SvgIcon/SvgIcon';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import LoadAsync from '_platform/src/utils/LoadAsync';
//import VideoEmbed from '../../components/VideoEmbed/VideoEmbed';

import imagePoints from './images/HP-Icon1-@2x.png';
import imageRewards from './images/HP-Icon2-@2x.png';
import imageStatus from './images/HP-Icon3-@2x.png';

import Step1 from './images/HP-Step1-@2x.png';
import Step2 from './images/HP-Step2-@2x.png';
import Step3 from './images/HP-Step3-@2x.png';

const RewardsProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "rewardsProvider" */ '../RewardsProvider/RewardsProvider'
  )
);

const AlternateContainer = styled.div`
  background-color: #231f20;
  color: #fff;
  padding: 2em 0;
  text-align: center;

  h1 {
    padding-top: inherit;
    color: #fff;
  }
  p {
    color: #fff;
    line-height: 1.5;
  }

  .step-container {
    border: 1px solid #fff;
    min-height: 405px;
  }
`;

const StyledRewardsProvider = styled.div`
  background-color: #231f20;
  color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;

  .rewards-highlight {
    background-color: transparent;
  }

  .rewards-list-item-title {
    padding: 0.5rem 0.25rem 1.5rem 0.25rem;
  }
  .rewards-list-item-inner {
    background-color: #f4f4f4;
    border: none;
  }

  .rewards-list-item-points,
  .rewards-list-item-actions {
    padding-bottom: 2rem;
  }
  .points-value {
    font-weight: bold;
    color: #000;
    span {
      color: #00d072;
    }
  }

  .rewards-list-item-button {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 2px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    text-transform: uppercase;
    background-color: #2b3033;
    color: #fff;
    &:hover {
      background-color: ${lighten(0.1, '#2b3033')} !important;
      color: #fff;
    }
  }
`;

const Featured = styled.h3`
  background-color: #000;
  color: #fff;
  font-size: 30px;
  width: 200px;
  height: 50px;
  text-align: center;
  margin-left: 85px;
`;

const HomePage = ({ settings }) => {
  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <AlternateContainer>
          <Grid>
            <Row className="spacer--bottom">
              <Col xs={12}>
                <h1 className="title">Welcome to HP Platinum Club</h1>
                <hr />
                <p className="text--left">
                  Welcome to the HP Platinum Club, designed to reward you for
                  your sales of selected HP products and for completing
                  training. The more selected HP products you sell, the more
                  points you earn and the more rewards you receive. Achieve 300
                  Points and you can claim a reward.
                </p>
                <p className="text--left">
                  You also earn points for completing HP training. HP has
                  partnered with EdApp as our innovative training platform
                  solution. Through EdApp, you will have access to all of the
                  training resources you need to become an expert on HP
                  products, how to upsell, cross sell and understand your
                  customers needs.
                </p>
                <hr />
                <h3 className="text--left">
                  HP want to partner with you and reward you for selling HP
                  products. Plus high achievers receive access to exclusive HP
                  events.
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <div className="step-container">
                  <IconWrapper>
                    <img src={Step1} alt="Step 1" />
                  </IconWrapper>
                  <h3 className="text--center text-white">Step 1</h3>
                  <p className="text--center text--large text--white">
                    Sell selected HP Products
                    <br />& complete training
                  </p>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="step-container">
                  <IconWrapper>
                    <img src={Step2} alt="Step 2" />
                  </IconWrapper>
                  <h3 className="text--center text-white">Step 2</h3>
                  <p className="text--center text--large text--white">
                    Earn points
                  </p>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="step-container">
                  <IconWrapper>
                    <img src={Step3} alt="Step 3" />
                  </IconWrapper>
                  <h3 className="text--center text-white">Step 3</h3>
                  <p className="text--center text--large text--white">
                    Claim your rewards!
                  </p>
                </div>
              </Col>
            </Row>
          </Grid>
        </AlternateContainer>
        {/*<BgGold>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} sm={8}>
                <VideoEmbed
                  videoId="Zq_t3APgghs"
                  title="Welcome to Platinum Club"
                />
              </Col>
            </Row>
          </Grid>
        </BgGold>
      */}
        <Grid>
          <Row
            className="spacer--around--small"
            justifyContent="center"
            //style={{ borderBottom: '1px solid #32343B' }}
          >
            <Col xs={12} md={4} className="text--center">
              <div className="panel-image spacer--top">
                <img src={imagePoints} alt="Rewards collage" />
              </div>
              <Featured>Points</Featured>
              <p className="spacer--bottom--large text--center">
                Check out the <Link to="/products">participating products</Link>{' '}
                and the points you can earn for each.
              </p>
            </Col>
            <Col xs={12} md={4}>
              <div className="panel-image spacer--top">
                <img
                  src={imageRewards}
                  alt="Man in 90s denim jacket using a computer"
                />
              </div>
              <Featured>Rewards</Featured>
              <p className="spacer--bottom--large text--center">
                Use your points to claim rewards. The more points you earn, the
                more or higher value rewards you can claim. Choose a digital
                gift card from top retailers and brands, some HP merchandise or
                a reward delivered directly to you.
              </p>
            </Col>
            <Col xs={12} md={4}>
              <div className="panel-image spacer--top">
                <img src={imageStatus} alt="Celebrating with champagne" />
              </div>
              <Featured className="text--center">Status</Featured>
              <p className="spacer--bottom--large text--center">
                Earn more points to move up the status tiers. Your status opens
                up access to exclusive HP events. The higher your status tier
                the more events available for you to attend.
              </p>
            </Col>
          </Row>
          <Row
            justifyContent="center"
            className="spacer--around--small"
            style={{ borderBottom: '1px solid #000' }}
          >
            <Grid>
              <Row justifyContent="center">
                <Col xs={12}>
                  <h1 className="title text--black">Platinum Club Status</h1>
                  <hr className="black" />
                  <p className="text--center">
                    The amount of points you earn and your rank amongst your
                    peers as well as completion of training determines your
                    status. There are 3 levels of Platinum Club status with
                    increasing benefits. When you first join the program your
                    default membership status is SILVER (entry level). From
                    there you can be promoted to GOLD or PLATINUM based on your
                    performance.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6} md={4}>
                  <IconWrapper>
                    <SvgIcon
                      icon="icon_token"
                      scaleWidth="42"
                      scaleHeight="42"
                      width="214px"
                      height="214px"
                      fill="#b7b7b7"
                      title="Status"
                    />
                  </IconWrapper>
                  <Featured className="text--center">Silver</Featured>
                </Col>
                <Col sm={6} md={4}>
                  <IconWrapper>
                    <SvgIcon
                      icon="icon_token"
                      scaleWidth="42"
                      scaleHeight="42"
                      width="214px"
                      height="214px"
                      fill="#d09b42"
                      title="Status"
                    />
                  </IconWrapper>
                  <Featured className="text--center">Gold</Featured>
                </Col>
                <Col sm={6} md={4}>
                  <IconWrapper>
                    <SvgIcon
                      icon="icon_token"
                      scaleWidth="42"
                      scaleHeight="42"
                      width="214px"
                      height="214px"
                      fill="#7b7b7b"
                      title="Status"
                    />
                  </IconWrapper>
                  <Featured className="text--center">Platinum</Featured>
                </Col>
              </Row>
            </Grid>
          </Row>
        </Grid>
        <StyledRewardsProvider className="rewards-provider">
          <Grid>
            <Row justifyContent="center">
              <Col>
                <RewardsProvider
                  showFeatured
                  rewardLabels={{
                    highlightHeading: 'Rewards of the month',
                    highlightTagline: '',
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </StyledRewardsProvider>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
